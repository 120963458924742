<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, customFields, tags, metadata, description, version, name, uptime, constraints, statistics, lastKnownStatusCode]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="computeEngines"
  />
</template>
<script>
import { computeEngines } from '@/config/descriptions/computeEngines';
import { computeEnginesTexts } from '@/config/pageTexts/computeEngines.json';

export default {
  name: 'ComputeEnginesTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'computeEngines/computeEngines',
          fields: 'computeEngines/fields',
          sortableFields: 'computeEngines/sortableFields',
          pages: 'computeEngines/pages',
          loading: 'computeEngines/loading',
          resultCount: 'computeEngines/resultCount',
          totalRecords: 'computeEngines/totalRecords',
          originalFields: 'computeEngines/originalFields',
          searchableAllFields: 'computeEngines/searchableAllFields',
          success: 'computeEngines/success',
          message: 'computeEngines/message',
        },
        actions: {
          fields: 'computeEngines/handleFields',
          delete: 'computeEngines/handleDeleteComputeEngines',
          reset: 'computeEngines/handleClearFilter',
          query: 'computeEngines/handleGetComputeEnginesByQuery',
        },
      },
      settings: {
        describe: { ...computeEngines },
        page: { ...computeEnginesTexts },
      },
      route: {
        create: 'CreateComputeEngine',
        edit: 'EditComputeEngine',
        preview: 'PreviewComputeEngine',
      },
    };
  },
};
</script>