<template>
  <ComputeEnginesTable/>
</template>
<script>
import ComputeEnginesTable from '@/views/ziqni/settings/computeEngines/ComputeEnginesTable';

export default {
  components: {
    ComputeEnginesTable
  },
  props: {}
};
</script>